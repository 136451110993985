@import '../abstracts/helpers';
@import '../abstracts/variables';

.trusted-logos-container {
  margin-bottom: @spacer * 3;

  p {
    font-weight: 600;
    font-size: @font-size-large;
    font-family: @headings-font-family;
    margin-bottom: @spacer;
    color: @dim-mid;

    @media @below-sm {
      text-align: center;
    }

    span {
      color: @primary-darker;
    }
  }

  .trust-logos {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: @spacer * 2;

    // Magic number width to match Figma and !important to override inline width
    > div {
      width: 300px !important;
      display: inline-block;

      // Remove ghost span caused by Img component
      span > span {
        display: none !important;
      }

      @media @below-md {
        width: 270px !important;
      }

      @media @below-sm {
        width: unset !important;
      }
    }

    @media @below-sm {
      justify-content: center;
    }
  }

  &.centered {
    text-align: center;

    .trust-logos {
      justify-content: center;
    }
  }
}
