@import '../abstracts/variables';
@import '../abstracts/helpers';
@import './CarouselLandingPage.module.less';
@import '../ProductCard.module';

.supplier-circles-carousel-wrapper {
  margin: @spacer * 5 0;
  height: 100px;

  h2 {
    margin-bottom: @spacer * 2;
  }

  .supplier-circles-carousel {
    & > div {
      max-width: 100%;

      @media @below-sm {
        margin: 0;
      }
    }

    :global {
      .scroll-container-ref {
        margin: 0;
        margin-right: 0;
        padding: @spacer 0;

        > div {
          &:first-child {
            margin-left: @spacer;
          }

          &:last-child {
            margin-right: 0;
          }
        }
      }
    }

    .carousel-card {
      .product-card;

      @card-height: 90px;

      :global(.rs-panel) {
        border-radius: 50%;
        height: @card-height;
        width: @card-height;
        margin: auto;
      }
    }
  }
}
