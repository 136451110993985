@import '../abstracts/variables';

.features-benefits-section {
  font-size: @font-size-large;

  .row {
    display: flex;
    flex-wrap: nowrap;
    gap: @spacer * 4;
    margin-top: @spacer * 10;
    margin-bottom: @spacer * 10;

    .features-benefits-content {
      flex: 1;

      .tagline {
        font-family: @headings-font-family;
        font-size: @font-size-base + 4;
        font-weight: 600;
        color: @primary-dark;
      }

      .headline {
        margin-bottom: @spacer * 1.5;
        margin-top: @spacer;
        font-family: @headings-font-family;
        font-size: 32px; // Magic number to match design
        max-width: 450px;
      }

      .description {
        line-height: 32px;
        color: @B800;
      }

      ul {
        padding: 0;
        margin-top: @spacer * 2;
        list-style: none;

        li {
          padding-left: @spacer * 3;
          padding-bottom: @spacer;
          background-image: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjEiIGhlaWdodD0iMjAiIHZpZXdCb3g9IjAgMCAyMSAyMCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik0xMC4wMzkxIDBDNC41MDM2NCAwIDAgNC40ODQ5MyAwIDkuOTk5OTlDMCAxNS41MTQgNC41MDM2NCAxOS45OTk0IDEwLjAzOTEgMTkuOTk5NEMxNS41NzQ2IDE5Ljk5OTQgMjAuMDc4MyAxNS41MTQgMjAuMDc4MyA5Ljk5OTk5QzIwLjA3ODMgNC40ODQ5MyAxNS41NzQ2IDAgMTAuMDM5MSAwWk0xNS42MzU5IDYuNjY1MDNMOS40NTc5OSAxMy41ODgxQzkuMzA1ODggMTMuNzU4IDkuMDkzOTEgMTMuODQ1NyA4Ljg4MDMzIDEzLjg0NTdDOC43MTA4NyAxMy44NDU3IDguNTQwNiAxMy43OTAzIDguMzk4MTQgMTMuNjc3OUw0LjUzNjg0IDEwLjYwMDVDNC4yMDQgMTAuMzM1NSA0LjE1MDAxIDkuODUxMDYgNC40MTY0MyA5LjUxODhDNC42ODI1MiA5LjE4NzYzIDUuMTY4OTUgOS4xMzMzMiA1LjUwMTggOS4zOTg5TDguNzkwMzcgMTIuMDE5M0wxNC40ODE0IDUuNjQyNDVDMTQuNzY0MSA1LjMyNDE4IDE1LjI1MjkgNS4yOTU2OCAxNS41NzE0IDUuNTc4NDlDMTUuODkwNCA1Ljg2MDc0IDE1LjkxOTMgNi4zNDY3NiAxNS42MzU5IDYuNjY1MDNaIiBmaWxsPSIjMDU0QzJFIi8+Cjwvc3ZnPgo=');
          background-repeat: no-repeat;
          background-position: 0 10%;
        }
      }

      .cta {
        button {
          font-family: @font-family-base;
          font-weight: 600;
          padding: 0;
          border: none;
          margin: 0;
          color: @primary-darker;
        }
      }
    }

    .image-container {
      flex: 1;
    }

    @media @below-md {
      flex-direction: column;
    }
  }

  // Alternate row layout on small screens
  @media @below-md {
    .row:nth-child(even) {
      flex-direction: column-reverse;
    }

    .row .image-container {
      display: flex;
      justify-content: center;
    }
  }
}
