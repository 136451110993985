@import 'abstracts/variables';

.product-card-narrow-wrapper-link {
  @card-dimension: 210px;

  display: block;
  text-decoration: none;
  color: @text;

  // Default fixed width for carousels
  min-width: @card-dimension;
  width: @card-dimension;

  // Remove fixed width when inside a grid layout
  &.is-grid-item {
    width: auto;
    min-width: auto;
  }

  &:hover,
  &:active,
  &:focus {
    text-decoration: none;
    color: @text;
  }

  .product-card-narrow {
    flex-direction: column;
    height: 100%;
    background: @white;
    padding: @spacer;
    border-radius: @border-radius;
    transition: transform @transition-delay ease-out, box-shadow @transition-delay ease-out;

    &.product-card-border {
      border: 1px solid @B200;
    }

    &:hover {
      transform: translate3d(0, -4px, 0);
    }

    p {
      margin: 0;
    }

    .image-container {
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      margin-bottom: @spacer;

      img {
        object-fit: contain;
      }
    }

    .tag {
      margin: (@spacer / 2) 0;
      color: @B800;
    }

    .product-name {
      font-family: @headings-font-family;
      font-weight: 600;
      font-size: @font-size-base;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
      margin-bottom: (@spacer / 2);
      min-height: calc(@font-size-base * @line-height-base * 2); // Font size × line height × number of lines
    }

    .strike-through-price {
      font-size: @font-size-small - 1;
      font-weight: 600;
      text-decoration: line-through;
      color: @red;
    }

    .reference-price {
      font-weight: 600;

      span {
        font-size: @font-size-small;
      }
    }

    .supplier-name {
      margin-top: @spacer;
    }

    .product-card-buttons {
      margin-top: @spacer;
    }
  }
}
