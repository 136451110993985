@import '../abstracts/variables';

@homepage-h1: 36px;

.hero-section-container {
  .hero-section-logo;

  color: @B800;

  h1,
  .h1 {
    font-size: @homepage-h1;
  }

  .tagline {
    font-size: @font-size-base + 4.5;
    color: @dim-darker;
  }

  .left-column {
    max-width: 550px;
  }

  .company-logos {
    margin: @spacer * 3 0;
    position: relative;
  }

  h1 {
    margin-bottom: @spacer * 1.5;
    font-size: @homepage-h1;

    @media @below-md {
      font-size: 28px;
    }

    span {
      color: @primary-dark;
    }
  }

  .subtitle {
    font-family: @headings-font-family;
    font-size: @font-size-large;
    margin-bottom: @spacer * 2;
    max-width: 550px;
  }

  .start-sourcing-button {
    border-color: @primary-dark;
    color: @primary-dark;

    &:hover {
      border-color: @primary-darker;
      color: @primary-darker;
    }
  }

  .cta-link {
    text-decoration: none;

    &:hover {
      text-decoration: none;
    }
  }

  .cta-button {
    padding: @spacer * 1.5 @spacer * 3;
    height: 100%;
    text-decoration: none;

    &:hover {
      text-decoration: none;
    }

    &.primary::after {
      background-image: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNSIgaGVpZ2h0PSI3IiB2aWV3Qm94PSIwIDAgNSA3IiBmaWxsPSJub25lIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPgo8cGF0aCBkPSJNMC41NDEzNjMgMC4zMzAxMDhDMC42MzQyNTQgMC4yMTU5NzMgMC43ODU4ODUgMC4xOTUwNjcgMC44OTk5ODUgMC4yNzI5OTNMMC45NDA4MjUgMC4zMDY4NTlMMy43NjkzNiAzLjEyMTkzQzMuODgwMzQgMy4yMzIzOCAzLjg5MjY3IDMuNDE1NjkgMy44MDYzNSAzLjU0MjMzTDMuNzY5MzYgMy41ODY5MUwwLjk0MDgyNSA2LjQwMTk4QzAuODI0NzExIDYuNTE3NTQgMC42NDU4NjYgNi41MDcxNCAwLjU0MTM2MyA2LjM3ODczQzAuNDQ4NDcxIDYuMjY0NiAwLjQ0NTU4NyA2LjA5NTY1IDAuNTI3NjQ0IDUuOTc4MzZMMC41NjIzODcgNS45MzdMMy4xNTY5OSAzLjM1MzhMMC41NjIzODcgMC43NzE4NDJDMC40NTkxNzQgMC42NjkxMjEgMC40NDAyNjkgMC41MDE0NDQgMC41MTA3MzggMC4zNzUyN0wwLjU0MTM2MyAwLjMzMDEwOFoiIGZpbGw9IiMwNTRDMkUiIHN0cm9rZT0iIzA1NEMyRSIgc3Ryb2tlLXdpZHRoPSIwLjMyMzI2MSIvPgo8L3N2Zz4=') !important;
    }
  }

  .cta-text {
    margin-top: @spacer;
    padding-right: @spacer * 5;
    color: @B500;
  }

  .new-other-logos {
    margin-left: @spacer * 2;
    position: relative;
    right: 0;
  }

  .new-banner-container {
    display: flex;
    justify-content: flex-end;
    align-self: center;

    @media @below-lg {
      align-self: flex-start;
      top: 40px; // Stop moving the banner down when the screen is smaller
    }

    @media @below-md {
      display: none;
    }
  }

  @media @below-lg {
    .cta-button {
      padding: @spacer * 1.5 @spacer * 2;
    }

    .company-logos {
      margin: @spacer * 2 0;
    }
  }

  @media @below-md {
    .left-column {
      margin-top: @spacer * 6;
    }
  }

  @media @below-sm {
    .company-logos {
      margin: @spacer 0;
    }
  }
}

.recommended-products-section {
  margin: @spacer * 6 0;
}

.hero-section-logo {
  .company-logos {
    flex-wrap: nowrap;
    height: 60px;
    position: relative;

    @media @below-md {
      flex-wrap: nowrap;

      svg {
        width: 76%; // to match figma
      }
    }

    .logo-image {
      mix-blend-mode: multiply;
    }
  }

  .other-logos {
    white-space: nowrap;
    font-size: @font-size-base + 1;
    color: @B400;
    font-weight: 700;
    font-family: @headings-font-family;
    font-style: normal;
    position: absolute;
    right: -130px; // adjust according to figma

    @media @below-md {
      margin-left: @spacer * 2;
      position: relative;
      right: 0;
      white-space: nowrap;
    }

    @media @below-sm {
      font-size: @font-size-base - 4;
    }
  }
}

.landing-page-main {
  margin: 0  @spacer * 4.5 @spacer * 5 @spacer * 4.5;

  &.with-hero-section-container {
    @media @below-sm {
      margin-right: @spacer * 2;
    }
  }

  .gradient-container {
    margin-top: 0;

    .banner-container {
      & > div {
        float: right;

        img {
          max-height: 338px !important; // Match inherent height of image
        }

        @media @below-lg {
          margin-top: @spacer * 3;
        }
      }
    }

    @media @below-md {
      margin: 0 -@spacer * 2;
    }
  }

  .top-gradient-section {
    width: 100%;
    padding: @spacer * 5 @spacer * 6 @spacer * 4 @spacer * 10;
    background: @gradient-background;
    border-radius: @border-radius-base - 2;

    .h1-hero {
      font-size: @homepage-h1;
      overflow-wrap: break-word;
      hyphens: auto;

      @media @below-md {
        font-size: 32px; // to match figma mobile
      }
    }

    .description-text {
      margin-top: @spacer * 2;
      color: @B800;
      font-size: @font-size-large;

      @media @below-md {
        margin-top: @spacer * 3;
      }
    }

    .hero-section-logo;

    @media @below-md {
      padding: @spacer * 6 @spacer * 2;
      padding-bottom: @spacer * 3;
    }
  }

  .features-banner {
    @carousel-overlay-offset: @spacer * 20;

    background: @dim-mid;
    padding: @spacer * 6 @spacer * 3;
    margin-top: @spacer * 4;
    border-radius: @border-radius-base - 2;
    margin-bottom: @carousel-overlay-offset;

    .features-banner-carousel {
      margin: 0 -(@spacer * 3) -(@carousel-overlay-offset) -(@spacer * 3);
      padding-bottom: @spacer;
    }

    .headline {
      margin: @spacer * 1.5 0 @spacer * 2 0;
    }

    .features-introduction {
      margin: 0 auto;
      margin-bottom: @spacer * 6;
      font-family: @headings-font-family;
      color: @white;
      max-width: 770px;
      text-align: center;

      h2 {
        color: @primary;
        margin: 0;
      }
    }

    .feature-section {
      .title {
        margin-bottom: @spacer;
      }

      p {
        color: @B800;
      }
    }

    @media @below-md {
      margin-top: @spacer * 4;
      margin-bottom: @carousel-overlay-offset;
      padding: @spacer * 4 @spacer * 6;
      text-align: center;

      .feature-section {
        margin-bottom: @spacer * 5;

        &:last-child {
          margin-bottom: 0;
        }

        .title {
          margin-top: @spacer * 1.5;
        }
      }

      :global {
        .rs-col {
          float: revert;
        }
      }
    }

    // Make full width and increase space for mobile screens
    @media @below-sm {
      padding: @spacer * 5 @spacer * 3;
      margin-left: -(@spacer * 2);
      margin-right: -(@spacer * 2);
      border-radius: 0;
    }
  }

  .favourite-products {
    @media @below-md {
      margin: @spacer * 5 0 @spacer * 2;
    }

    h4 {
      color: @dim-mid;
    }

    .browse-products {
      font-size: @font-size-base + 2;

      > a {
        color: @primary-dark;

        > svg {
          fill: @primary-dark;
        }
      }
    }

    :global {
      .scroll-container-ref > div:last-child {
        margin-right: @spacer * 2;
      }
    }
  }

  @media @below-md {
    margin: -@spacer * 2 @spacer * 2 0;
  }
}
