@import 'abstracts/variables';

.section-container {
  margin: @spacer * 10 auto @spacer * 6;
  align-items: stretch;
  max-width: 1000px; // To match design

  h2 {
    text-align: center;
    padding-bottom: @spacer * 4;
  }

  :global(.rs-panel-body) {
    padding: 0;
  }

  :global(.rs-col) {
    padding: 0;
  }
}

.right-column {
  padding: @spacer * 5 @spacer * 3;
  margin-left: @spacer * 3;
  color: @dim-mid;

  div:global(.h2) {
    margin-bottom: @spacer * 2;
  }

  :global(.rs-panel-body) {
    max-width: 400px;
    margin: 0 auto;
  }

  @media @below-md {
    :global(.rs-panel-body) {
      margin: 0 auto;
    }
  }

  @media @below-md {
    padding-top: @spacer * 3;
    padding-bottom: 0;
    margin-left: 0;
  }
}

.left-column {
  padding: @spacer * 5 @spacer * 3;
  height: 100%;

  :global(.rs-panel-body) {
    max-width: 350px;
    margin: 0 auto;
  }

  .headline {
    color: @dim-mid;
  }

  p {
    max-width: 300px;
    color: @B800;
  }
}

@media @below-lg {
  .left-column {
    margin-bottom: @spacer * 3;
    height: auto;
  }

  .right-column {
    margin: 0;
  }
}

@media @below-sm {
  .right-column {
    padding-left: @spacer;
  }
}
