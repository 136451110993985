@import 'abstracts/spacers';
@import 'abstracts/helpers';

.cta-card-container {
  text-align: left;
  @avatar-dimension: 45px; // Magic number for product specialist image sizing

  p + p {
    margin-top: (@spacer/2); // Override original rule to match design
  }

  .product-specialist-name {
    color: @B600;
  }

  .avatar-dimension(md, @avatar-dimension);

  ul.cta-wrapper {
    display: flex;
    min-height: @avatar-dimension; // Match CTA wrapper with avatar height
    justify-content: center;
    flex-direction: column;
    list-style-type: none;
    gap: (@spacer / 2);
    padding: 0;
    margin: 0;

    .title {
      color: @dim-mid;
      font-weight: 600;
    }

    .contact-us-button {
      display: block;
      background: none; // Clear background from default button element
      font-family: @font-family-base;
      color: @anchor-color;
      cursor: pointer;
      padding: 0;

      &:hover,
      &:focus {
        color: @anchor-color-hover; // Override black hover color
        text-decoration: underline;
      }
    }
  }
}
