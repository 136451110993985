@import 'abstracts/variables';

.product-card-buttons {
  display: flex;
  gap: @spacer;

  button {
    width: 45px; // Magic number to keep buttons square
    height: 45px;
  }

  .save-product-button {
    border-radius: @border-radius;
    padding: @spacer;
    border: 1px solid @B200;
    background: @white;

    &:hover {
      background-color: @B100;
    }

    svg {
      width: 24px;
      height: 24px;
    }
  }

  .add-to-cart-button {
    border-radius: @border-radius;
    padding: @spacer;
    flex: 1 1;

    svg {
      width: 26px;
      height: 26px;

      path {
        stroke-width: 4;
      }
    }
  }
}
